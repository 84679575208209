<template>
  <div style="height:100%;width:100%;position:relative;overflow:hidden">
    <GmapMap
      style="height:100%;width:100%"
      :center="center"
      :zoom="zoom"
      v-bind:options="mapStyle"
    >
      <GmapMarker 
        v-for="(device) in devices" 
        v-if="device.location"
        :key="device._id" 
        ref="myMarker" 
        :position="google && new google.maps.LatLng(device.location.lat, device.location.lng)" 
        :icon="{...icon,anchor:!!google?new google.maps.Point(200,500):null}"
        @click="selectDevice(device)"
      />
      <GmapCluster
        :gridSize="20"
        :zoomOnClick="true"
        :ignoreHidden="true"
        :minimumClusterSize="10"
        ref="cluster"
        :calculator="clusterCalculate"
      >
        <GmapMarker 
          v-for="(points) in gpsPoints" 
          :key="points._id" 
          ref="myMarker" 
          :position="google && new google.maps.LatLng(points.lat, points.lng)" 
          :icon="{...circleIcon,anchor:!!google?new google.maps.Point(200,500):null}"
        />
      </GmapCluster>
      <gmap-info-window
          v-for="(device) in devices"
          v-if="device.location"
          :key="device._id+'info'"
          :options="{
            boxClass: 'red',
            maxWidth: 300,
            minWidth: 300,
            pixelOffset: { width: 0, height: -35 },
            buttons: { close: { visible: false } }
          }"
          
          :position="google && new google.maps.LatLng(device.location.lat, device.location.lng)"
          :opened="true"
        >
          <div style="height:30px">
            <div style="background:#2B2B2B;position:absolute;left:0;top:0;height:54px;width:50px">
              <div class="text-center text-h5 white--text" style="margin-top:10px;text-transform: uppercase;">
                {{device.driver.name.substring(0,1)}}
                {{device.driver.last_name.substring(0,1)}}
              </div>
              
            </div>
            <div style="font-size:12px,position:absolute;left:50px;margin-left:50px;margin-right:50px" class="text-center">
              <div class="text-center">
                {{device.driver.name}}
              </div>
              
            </div>
            <div style="background:#2B2B2B;position:absolute;left:250px;top:0;height:54px;width:50px">
              <div class="text-center text-h5 white--text mx-2" style="margin-top:10px;text-transform: uppercase;">
                {{device.driver.e_number}}
              </div>
              
            </div>
          </div>
        </gmap-info-window>
    </GmapMap>
    <div class="right-card" :style="`${activeDeviceInfo?'right:0px':''}`" >
      <v-card height="95%" max-width="400px">
        <v-card-title primary-title>
          Información 
          <v-spacer></v-spacer>
          <v-btn color="grey" icon small @click="activeDeviceInfo=false">
            <v-icon small>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-xs>
            <v-row>
                  <v-col class="text-left font-weight-bold">
                    Última conexión
                  </v-col>
                  <v-col>
                    {{$moment(selectedItem.updated_at).format('ddd DD-MM-YYYY HH:mm')}}
                  </v-col>
                  
                </v-row>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-title >
                <span class="text-subtitle-1">Configuración</span>
              </v-card-title>
              <v-card-text>
                
                <v-row>
                  
                  <v-col class="text-left font-weight-bold">
                    Seguir
                  </v-col>                    
                  <v-spacer></v-spacer>
                    <v-switch
                      v-model="selectedItem.follow"
                      color="red"
                      hide-details
                      inset
                      :value="true"
                      @change="follow($event)"
                    ></v-switch>
                </v-row>
                <v-row>
                  <v-col class="text-left font-weight-bold">
                    Bloquear
                  </v-col>                    
                  <v-spacer></v-spacer>
                    <v-switch
                      :disabled="selectedItem.status=='Inactivo'"
                      v-model="selectedItem.status"
                      color="red"
                      value="Bloqueado"
                      hide-details
                      inset
                      @change="lockUnlockDevice($event)"
                    ></v-switch>
                </v-row>
              </v-card-text>
            </v-card>
            <RutesByDevice :device="selectedItem" @get-points="gpsPoints=$event"/>
            
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import Device from "@/services/Device";
import GpsData from "@/services/GpsData";
import motoStyle from "@/components/maps/motoStyle";
import darkStyle from "@/components/maps/darkStyle";
import RutesByDevice from "@/components/RutesByDevice";
export default {
  name: 'Home',
  components: {
    RutesByDevice
  },
  data:()=>({
    activeDeviceInfo:false,
    center: {
      lat: 19.3910038,
      lng: -99.1580427,
    },
    zoom:12,
    devices:[],
    icon:{
        path: "M512.9 192c-14.9-.1-29.1 2.3-42.4 6.9L437.6 144H520c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24h-45.3c-6.8 0-13.3 2.9-17.8 7.9l-37.5 41.7-22.8-38C392.2 68.4 384.4 64 376 64h-80c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h66.4l19.2 32H227.9c-17.7-23.1-44.9-40-99.9-40H72.5C59 104 47.7 115 48 128.5c.2 13 10.9 23.5 24 23.5h56c24.5 0 38.7 10.9 47.8 24.8l-11.3 20.5c-13-3.9-26.9-5.7-41.3-5.2C55.9 194.5 1.6 249.6 0 317c-1.6 72.1 56.3 131 128 131 59.6 0 109.7-40.8 124-96h84.2c13.7 0 24.6-11.4 24-25.1-2.1-47.1 17.5-93.7 56.2-125l12.5 20.8c-27.6 23.7-45.1 58.9-44.8 98.2.5 69.6 57.2 126.5 126.8 127.1 71.6.7 129.8-57.5 129.2-129.1-.7-69.6-57.6-126.4-127.2-126.9zM128 400c-44.1 0-80-35.9-80-80s35.9-80 80-80c4.2 0 8.4.3 12.5 1L99 316.4c-8.8 16 2.8 35.6 21 35.6h81.3c-12.4 28.2-40.6 48-73.3 48zm463.9-75.6c-2.2 40.6-35 73.4-75.5 75.5-46.1 2.5-84.4-34.3-84.4-79.9 0-21.4 8.4-40.8 22.1-55.1l49.4 82.4c4.5 7.6 14.4 10 22 5.5l13.7-8.2c7.6-4.5 10-14.4 5.5-22l-48.6-80.9c5.2-1.1 10.5-1.6 15.9-1.6 45.6-.1 82.3 38.2 79.9 84.3z",
        fillColor: '#FFA200',
        fillOpacity: .9,
        
        strokeWeight: 1,
        scale: .06
    },
    circleIcon:{
        path: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z",
        fillColor: '#DE0076',
        fillOpacity: .9,
        
        strokeWeight: 1,
        scale: .02
    },
    selectedItem:{

    },
    gpsPoints:[]
  }),
  sockets: {
    connect: function () {
      console.log("conectado");
    },
    "gps-data": function (data) {
      var index=-1;
      this.devices.find((item,i)=>{
        if(item._id==data.device){
          index=i;
          return;
        }
      });
      this.devices[index]=Object.assign(this.devices[index],{location:data});
      if (this.selectedItem.follow) {
        this.center=data;
        if(this.selectedItem.rutes){
          this.gpsPoints.push(data);
        }
      }
    
    },
  },
  methods:{
    clusterCalculate(v){
      let index=0;
      let dv = v.length;
      while (dv !== 0) {
        dv = Math.floor(dv / 10);
        index++;
      }
      return {
        text: "Detenido",
        index: index,
      };
    },
    async loadDevices(){
      let res=await Device.getDevices();
      this.devices=res;
    },
    selectDevice(item){
      this.selectedItem=Object.assign({},item);
      this.activeDeviceInfo=true;
    },
    async lockUnlockDevice(status){
      status=!status?'Activo':status;
      try {
        let res=await Device.lockUnlock(this.selectedItem._id,status);
        this.selectedItem.status=status;
      } catch (error) {
        console.log(error);
      }

    },
    follow(follow){
      if(follow){
        this.center=this.selectedItem.location;
        this.zoom=17;
      }else{
        this.center={lat: 19.3910038,lng: -99.1580427};
        this.zoom=12;
      }
      this.center=follow?this.selectedItem.location:{lat: 19.3910038,lng: -99.1580427};
    },
    async getRoutes(e){
      console.log(e);
      if(e){
        try {
          let res=await GpsData.getData({});
          this.gpsPoints=res;
          console.log(res.length);
        } catch (error) {
          console.log(error);
        }
      }else{
        this.gpsPoints=[]
      }
    }
  },
  created(){
    this.loadDevices();
  },
  computed:{
    google: gmapApi,
    mapStyle(){
      let opt={
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
      }
      // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
      return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign(motoStyle,opt)
    }
  }
}
</script>
<style scoped>
  .right-card{
    -webkit-transition: all .50s ease .10s; 
    -moz-transition: all .50s ease .10s; 
    -o-transition: all .50s ease .10s; 
    -ms-transition: all .50s ease .10s; 
    transition: all .50s ease .10s;
    width: 400px;
    height:100%;
    position: absolute;
    right:-400px;
    top: 0px;
    padding:10px;
    overflow: hidden;
  }
</style>
