<template>
    <div>
        <v-overlay
          :absolute="true"
          :value="active"
        >   
            <div class="text-center">
                
                
                <v-progress-circular
                v-if="progress"
                :color="`${color?color:'primary'}`"
                :value="progress"
                :rotate="360"
                :size="dataSize+50"
                :width="15"
                >
                |{{progress}}%
                </v-progress-circular>
                <v-progress-circular
                v-else
                :color="`${color?color:'primary'}`"
                indeterminate
                :size="dataSize"
                :width="5"
                >
                    <v-img src="@/assets/icono.png" width="30" ></v-img>
                </v-progress-circular>
                <br>
                {{progress?'Enviando':""}}
                <div class="text-center">
                    CRECER EN COLECTIVO
                </div>
                <div>
                    Procesando....
                </div>
            </div>
            
            
        </v-overlay>
    </div>
</template>
<script>
export default {
    props:["active","progress","color","size"],
    data:()=>({
        dataSize:0
    }),
    created(){
        this.dataSize=this.size||50;
    }
}
</script>