<template>
    <v-container grid-list-xs>
        <Loading :active="loading"/>
        <v-card>
            <v-card-title primary-title>
                Tarjetas
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="cardDialog=!cardDialog" small>
                    nuevo
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                >
                    <template v-slot:item.status="{ item }">
                        <v-checkbox
                        :label="item.status=='Activa'?'Activa':'Inactiva'"
                        v-model="item.status"
                        value="Activa"
                        @change="changeStatus($event,item)"
                        ></v-checkbox>
                    </template>
                    
                    <template v-slot:item.money="{ item }">
                        {{!item.driver?item.money:'--'}}
                    </template>
                    <template v-slot:item.driver="{ item }">
                        {{!!item.driver?item.driver.name:''}}
                    </template>
                    <template v-slot:item.qrCode="{ item }">
                        <v-btn color="secondary" icon @click="qrGenerate(item)">
                            <v-icon small>fa-qrcode</v-icon>
                        </v-btn>                        
                    </template>
                    <template v-slot:item.options="{ item }">
                        <v-btn color="error" small icon @click="updateItem(item)">
                            <v-icon x-small>fa-pen</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="cardDialog"
            scrollable 
            persistent :overlay="false"
            max-width="450px"
            transition="dialog-transition"
        >
            <v-form lazy-validation ref="cardForm" @submit.prevent="save()">
                <v-card >
                    <v-card-title primary-title class="secondary white--text" style="height:80px">
                        {{cardTitle}}
                        <v-spacer></v-spacer>
                        <v-btn color="white" @click="closeDialog()" icon>
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs>
                            <v-row>
                                <v-col cols="12" md="">
                                    <v-text-field
                                        name="rfidCode"
                                        label="Código RFID"
                                        id="rfidCode"
                                        outlined
                                        dense
                                        v-model="editedItem.rfidCode"
                                    ></v-text-field>
                                    <v-text-field
                                        name="moneyCard"
                                        label="Ingresar monto inicial"
                                        id="moneyCard"
                                        outlined
                                        dense
                                        v-model="editedItem.money"
                                    ></v-text-field>
                                    <v-select
                                        :items="['Cliente','Conductor']"
                                        name="cardType"
                                        label="Tipo de tarjeta"
                                        id="cardType"
                                        outlined
                                        dense
                                        v-model="editedItem.type"
                                        @change="loadDrivers($event)"
                                    ></v-select>
                                    <v-select
                                        :items="drivers"
                                        return-object
                                        item-text="name"
                                        name="cardType"
                                        label="Tipo de tarjeta"
                                        id="cardType"
                                        outlined
                                        dense
                                        v-model="editedItem.driver"
                                        v-if="editedItem.type=='Conductor'"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" type="submit">Aceptar</v-btn>
                        <v-btn color="primary" outlined @click="closeDialog()">cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog
            v-model="qrDialog"
            scrollable 
            persistent :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title primary-title>
                    Codigo QR
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="qrDialog=!qrDialog" icon>
                        <v-icon small>fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-xs>
                        <v-img :src="qrCode">

                        </v-img>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import Cards from '@/services/Cards';
import Drivers from '@/services/Drivers';
import Loading from '@/components/Loading';
import QRCode  from 'qrcode'
export default {
    components:{        
        Loading
    },
    data:()=>({
        loading:false,
        cardDialog:false,

        headers:[
            {
                text: '#',
                align: 'center',                
                value: '_id'
            },
            {
                text: 'Código RFID',
                align: 'center',                
                value: 'rfidCode'
            },
            {
                text: 'Tipo',
                align: 'center',                
                value: 'type'
            },
            {
                text: 'Dinero Actual',
                align: 'center',                
                value: 'money'
            },  
            {
                text: 'Conductor',
                align: 'center',                
                value: 'driver'
            },          
            {
                text: 'Estatus',
                align: 'center',                
                value: 'status'
            },            
            {
                text: 'Codigo QR',
                align: 'center',                
                value: 'qrCode'
            },
            {
                text: '...',
                align: 'center',                
                value: 'options'
            },
        ],
        items:[],
        drivers:[],
        editedIndex:-1,
        editedItem:{

        },
        qrCode:"",
        qrDialog:false
    }),
    methods:{
        changeStatus(v,item){
            console.log(v);
            let self=this;
            this.$swal({
            allowOutsideClick:false,
            title: '¿Estás seguro?',
            text: !v?'La tarjeta dejara de estár activa':'Se reactivara la tarjeta',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, '+ (!v?'desactivar':'reactivar!'),
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
            }).then(async (result) => {
            if (result.isConfirmed) {
                self.loading=true;
                try {
                    await Cards.updateCard(item._id,{status:v?'Activa':'Inactiva'});
                        this.$swal.mixin({
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    }).fire({
                        icon: 'success',
                        title: 'El estatus ha cambiado'
                    })
                } catch (error) {
                    console.log(error);   
                }finally{
                    self.loading=false;
                }
                
            }else{
                item.status=!v?'Activa':'Inactiva'
            }
        })
        },
        async loadData(){
            this.loading=true;
            try {
                let res=await Cards.getCards();
                this.items=res;
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        },
        async save(){
            this.loading=true;
            try {
                if(!this.$refs.cardForm.validate())throw {error:"Validate form"}
                if(this.editedIndex==-1){
                    let res=await Cards.storeCard(this.editedItem);
                    this.items.push(res);
                   
                }else{
                    await Cards.updateCard(this.editedItem._id,this.editedItem);
                    this.items[this.editedIndex]=Object.assign(this.items[this.editedIndex],this.editedItem);
                }
                this.closeDialog();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        },
        closeDialog(){
            this.cardDialog=false;
            this.editedIndex=-1;
            this.editedItem={};
            this.$refs.cardForm.resetValidate();

        },
        updateItem(item){
            this.editedItem=Object.assign({},item);
            this.editedIndex=this.items.indexOf(item);
            this.cardDialog=true;
        },
        async loadDrivers(e){
            if(e=='Cliente'){
                this.editedItem.driver=null;
                return;
            }
            this.loading=true;
            try {
                let res=await Drivers.getDrivers();
                console.log(res);
                this.drivers=res;
            } catch (error) {
                console.error(error);
            }finally{
                this.loading=false;
            }
        },
        async qrGenerate(item){
            let res=await QRCode.toDataURL(process.env.VUE_APP_WEB_URL+"payments/card/"+item._id,{ type: 'svg' });            
            this.qrCode=res;
            this.qrDialog=true;
        }
    },
    created(){
        this.loadData();
        this.loadDrivers();
        
    },
    computed:{
        cardTitle(){
            return this.editedIndex==-1?'Nuevo tarjeta':'Editar tarjeta'
        }
    }
}
</script>