import Connection from './Connection'
const URL = process.env.VUE_APP_SERVER_URL+"cards";
class Devices extends Connection{
    
    constructor(){

    }
    static async getCards(){
        let result=(await this.get(URL)).data;
        return result;
    }
    static async storeCard(device){
        let result=(await this.post(URL,device)).data;
        return result;
    }
    static async updateCard(id,device){
        let url=URL+"/"+id
        let result=(await this.put(url,device)).data;
        return result;
    }
}
export default Devices;