<template>
    <v-dialog
        v-model="active"
        scrollable  
        persistent :overlay="false"
        max-width="650px"
        transition="dialog-transition"
    >
        <v-form>
            <v-card rounded="lg">
                <v-container grid-list-xs class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0 primary" align="center" justify="center">
                        <v-col cols="12" sm="6" md="6" class="pa-0 ma-0">
                            <div class="primary" style="height:100%">
                                <v-container grid-list-xs>
                                    <v-row>
                                        <v-col class="text-center">
                                            <div class="text-center text-overline white--text">
                                                {{!!dateRange[0]?$moment(dateRange[0]).format("dddd"):''}}
                                            </div>
                                            <div class="text-center text-h2 white--text">
                                                {{!!dateRange[0]?$moment(dateRange[0]).format("DD"):''}}
                                            </div>
                                            <div class="text-center text-overline white--text">
                                                {{!!dateRange[0]?$moment(dateRange[0]).format("MMMM"):''}}
                                            </div>
                                            <div class="text-center text-overline white--text">
                                                {{!!dateRange[0]?$moment(dateRange[0]).format("YYYY"):''}}
                                            </div>
                                        </v-col>
                                        <v-col v-if="$moment(dateRange[1]).diff($moment(dateRange[0]),'days')>=1">
                                            <div class="text-center text-overline white--text">
                                                {{!!dateRange[1]?$moment(dateRange[1]).format("dddd"):''}}
                                            </div>
                                            <div class="text-center text-h2 white--text">
                                                {{!!dateRange[1]?$moment(dateRange[1]).format("DD"):''}}
                                            </div>
                                            <div class="text-center text-overline white--text">
                                                {{!!dateRange[1]?$moment(dateRange[0]).format("MMMM"):''}}
                                            </div>
                                            <div class="text-center text-overline white--text">
                                                {{!!dateRange[1]?$moment(dateRange[1]).format("YYYY"):''}}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row >
                                            <v-col cols="6">
                                                <div class="white--text text-center">
                                                Hora inicio
                                                </div>
                                            <v-text-field
                                                dark
                                                name="name"
                                                type="time"
                                                v-model="startTime"
                                                id="id"
                                                rounded
                                                outlined
                                                filled
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <div class="white--text text-center">
                                                Hora fin
                                                </div>
                                            
                                            <v-text-field
                                                dark
                                                name="name"
                                                type="time"
                                                v-model="endTime"
                                                id="id"
                                                rounded
                                                outlined
                                                filled
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="pa-0 ma-0 text-center" >
                            <div class="white">
                                <v-container grid-list-xs>
                                    <v-row>
                                        <v-col>
                                            <v-date-picker
                                                v-model="dateRange"
                                                range
                                                no-title
                                                locale="es-419"
                                                @change="checkDates($event)"
                                            ></v-date-picker>
                                        </v-col>
                                    </v-row>
                                    <v-card-actions>                                           
                                            <v-btn color="primary" @click="updateDate()">
                                                Aceptar
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" outlined @click="closeDialog()">
                                                Cancelar
                                            </v-btn>
                                    </v-card-actions>
                                </v-container>
                            </div>
                        </v-col>
                    </v-row>                        
                </v-container>
            </v-card>
            
            
        </v-form>
    </v-dialog>
</template>
<script>
import GpsData from "@/services/GpsData";
export default {
    props:["device","active"],
    data:()=>({
        endTime:"23:59",
        startTime:"00:00",
        dialog:false,
        dateRange:[],
        gpsPoints:[]
    }),
    watch:{
        value(){
            
        }
    },
    methods:{
        checkDates(e){
            if(e.length>1 && this.$moment(e[1]).isBefore(this.$moment(e[0]))){
                this.dateRange=e.reverse();
            }else if(e.length==1){
                this.dateRange[e[0],e[0]]
            }
        },
        selectRange(){
            this.dialog=true;
        },
        async updateDate(){
            
            try {
                let res=await GpsData.getData({filter:{createAt:{$gte:this.$moment(this.dateRange[0]).format('YYYY-MM-DD')+" "+this.startTime,$lte:this.$moment(this.dateRange[1]).format('YYYY-MM-DD')+" "+this.endTime}}});
                this.gpsPoints=res;
                console.log(res);
                this.$emit('input',this.gpsPoints);
            } catch (error) {
                console.log(error);
            }
            
        },
        async closeDialog(){
            this.$emit('close-dialog',false);
        }
    },
    created(){
    },
    computed: {
      dateRangeText () {
        return this.dateRange.join(' ~ ')
      },
    },
}
</script>
<style >
    input[type="time"]::-webkit-calendar-picker-indicator {
       background: none;
    }
</style>