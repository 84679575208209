<template>
    <v-app>
        <v-container grid-list-xs>
            
            <v-card>
                <v-card-title primary-title>
                    Abona crédito a tu tarjeta
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4">
                            <div>
                                <v-card>
                                    <v-card-title primary-title>
                                        <span class="text-caption">
                                            Con tarjeta de débito/crédito
                                        </span>
                                        
                                    </v-card-title>
                                    <v-card-text>
                                        <img src="@/assets/images/5b3aa3f84413fVisa-MasterCard-in-Iran-.jpg" alt="" width="200px">
                                    </v-card-text>

                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div>
                                <v-card>
                                    <v-card-title primary-title>
                                        <span class="text-caption">
                                            Deposito en OXXO
                                        </span>
                                        
                                    </v-card-title>
                                    <v-card-text>
                                        <img src="@/assets/images/1200px-Oxxo_Logo.svg.png" alt="" width="200px">
                                    </v-card-text>

                                </v-card>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-app>
    
</template>
<script>
export default {
    props:["card"],
    data:()=>{

    }
}
</script>