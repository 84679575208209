import axios from 'axios'
const URL = process.env.VUE_APP_SERVER_URL+"drivers";
class Devices extends axios{
    
    constructor(){

    }
    static async getDrivers(){
        let result=(await this.get(URL)).data;
        return result;
    }
    static async getWithoutDevice(){
        let url=URL+"/without/device"
        let result=(await this.get(url)).data;
        return result;
    }
    static async findDriver(driver){
        let url=URL+"/"+driver;
        let result=(await this.get(url)).data;
        return result;
    }
    static async store(driver){
        let result=(await this.post(URL,driver)).data;
        return result;
    }
    static async update(id,device){
        let url=URL+"/"+id
        let result=(await this.put(url,device)).data;
        return result;
    }
}
export default Devices;