<template>
    <v-card elevation="0" rounded="lg" outlined>
        <v-card-title >
            <span class="text-subtitle-1">Rutas</span>
            <v-spacer></v-spacer>
            <v-btn color="success" x-small v-if="device.rutes" @click="filterDialog=true"> 
                filtro
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
            <v-col class="text-left font-weight-bold">
                Mostrar rutas
            </v-col>                    
            <v-spacer></v-spacer>
                <v-switch
                v-model="device.rutes"
                color="blue"
                hide-details
                inset
                :value="true"
                @change="getRoutes($event)"
                ></v-switch>
            </v-row>
            
        </v-card-text>
        <FilterDialog :active="filterDialog" @input="setPoints($event)" @close-dialog="filterDialog=false"/>
    </v-card>
</template>
<script>
import FilterDialog from "@/components/FilterDialog";
export default {
    props:['device'],
  name: 'Home',
  components: {
      FilterDialog
  },
  data:()=>({  
    filterDialog:false,  
    gpsPoints:[]
  }),
  sockets: {
    
  },
  methods:{
    
    async getRoutes(e){
      if(e){
        this.filterDialog=true;        
      }else{
        this.gpsPoints=[]
      }
    },
    setPoints(e){
        this.$emit("get-points",e);
        this.filterDialog=false;
    }
  },
  created(){
  },
  computed:{
    
  }
}
</script>