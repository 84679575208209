<template>
    <v-container fluid style="height:100%">
        <Loading :active="loading"></Loading>
        <v-card min-height="80%">
            <v-card-title primary-title>
                Conductores
                <v-spacer></v-spacer>
                <v-btn color="secondary" small @click="newDialog=true">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    
                >
                    <template v-slot:item.name="{ item }">
                        {{item.name}}
                    </template>
                    <template v-slot:item.birth_day="{ item }">
                        {{$moment().diff(item.birth_day,'years')}}
                    </template>
                    <template v-slot:item.options="{ item }">
                        <v-btn color="error" small icon @click="updateItem(item)">
                            <v-icon x-small>fa-pen</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="newDialog"
            scrollable
            persistent
            :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-form @submit.prevent="save()" lazy-validation ref="newForm">
                <v-card>
                    <v-card-title primary-title class="primary white--text" style="height:100px">
                        <span>{{title}}</span>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" icon @click="closeNewDialog()">
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs>
                            <v-text-field
                                name="eNumberField"
                                label="Económico"
                                id="eNumberField"
                                outlined
                                v-model="editedItem.e_number"
                                color="secondary"
                            ></v-text-field>
                            <v-text-field
                                name="emailField"
                                label="Correo electrónico"
                                id="emailField"
                                outlined
                                v-model="editedItem.email"
                                :disabled="editedIndex>=0"
                                color="secondary"
                            ></v-text-field>
                            <v-text-field
                                name="firstNameField"
                                label="Nombre(s)"
                                id="firstNameField"
                                outlined
                                v-model="editedItem.first_name"
                                color="secondary"
                            ></v-text-field>
                            <v-text-field
                                name="lastNameField"
                                label="Apellidos"
                                id="lastNameField"
                                outlined
                                v-model="editedItem.last_name"
                                color="secondary"
                            ></v-text-field>
                            <v-menu
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            max-width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    :value="computedDateFormattedMomentjs"
                                    clearable
                                    label="Fecha de nacimiento"
                                    readonly
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="editedItem.birth_day = null"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.birth_day"
                                    @change="dateMenu = false"
                                    :max="$moment().subtract(18,'years').format('YYYY-MM-DD')"
                                ></v-date-picker>
                            </v-menu>
                            <v-select
                                :items="['Masculino','Femenino']"
                                label="Genero"
                                name="GenderField"
                                id="GenderField"
                                outlined
                                v-model="editedItem.gender"
                            ></v-select>>
                            
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit">Aceptar</v-btn>
                        <v-btn color="primary" outlined @click="closeNewDialog()">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
import Device from '@/services/Device'
import Drivers from '@/services/Drivers'
import Loading from '@/components/Loading'
export default {
    components:{
        Loading
    },
    data:()=>({
        dateMenu:false,
        loading:false,
        newDialog:false,
        items:[],
        drivers:[],
        headers:[
            {
                text: '#',
                align: 'center',                
                value: 'e_number'
            },
            {
                text: 'Nombre',
                align: 'center',                
                value: 'name'
            },
        // { text: 'Medición', value: 'mesure' },
            { text: 'Email', value: 'email' },
            { text: 'Genero', value: 'gender' },
            { text: 'Edad', value: 'birth_day' },
            { text: '...', value: 'options', sortable:false},
        ],
        editedIndex:-1,
        editedItem:{

        }
    }),
    methods:{
        async loadDrivers(){
            try {
                let result=await Drivers.getDrivers();
                this.items=result;
            } catch (error) {
                console.log(error);
            }
        },
        updateItem(item){
            this.editedIndex=this.items.indexOf(item);
            this.editedItem=Object.assign({},item);
            this.newDialog=true;
        },
        closeNewDialog(){
            this.editedIndex=-1;
            this.editedItem={};
            this.$refs.newForm.resetValidation();
            this.newDialog=false;
        },
        async save(){
            this.loading=true;
            try {
                if(this.editedIndex>=0){
                    let res=await Drivers.update(this.editedItem._id,this.editedItem);
                    this.items[this.editedIndex]=Object.assign(this.items[this.editedIndex],this.editedItem);
                }else{
                    let res=await Drivers.store(this.editedItem);
                    this.items.push(res);                    
                }
                this.closeNewDialog();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        },
        
    },
    created(){
        this.loadDrivers();
    },
    computed:{
        title(){
            return this.editedIndex==-1?'Nuevo conductor':'Editar datos de conductor'
        },
        computedDateFormattedMomentjs () {
            return this.editedItem.birth_day ? this.$moment(this.editedItem.birth_day).format('dddd, MMMM Do YYYY') : ''
        },
    }
}
</script>