<template>
    <v-container >
        <Loading :active="loading"></Loading>
        <v-card >
            <v-card-title primary-title>
                Usuarios
                <v-spacer></v-spacer>
                <v-btn color="secondary" small @click="userDialog=!userDialog">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                >
                    <template v-slot:item.options="{ item }">
                        <v-btn color="error" small icon @click="updateItem(item)">
                            <v-icon x-small>fa-pen</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.avatar="{ item }">
                        <v-avatar
                            size="50"
                            color="grey lighten-2"
                        >
                            <img :src="baseUrl+item.avatar" alt="alt" v-if="item.avatar">
                            <v-icon v-else>fa-user</v-icon>
                        </v-avatar>
                    </template>
                </v-data-table>
            </v-card-text>
            
        </v-card>
        <v-dialog
            v-model="userDialog"
            scrollable 
            persistent 
            :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-form lazy-validation @submit.prevent="save()" ref="userForm">
                <v-card>
                    <v-card-title primary-title class="primary white--text" style="height:80px">
                        {{userTitle}}
                        <v-spacer></v-spacer>
                        <v-btn color="white" icon small @click="closeUserDialog()">
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs>
                            <v-text-field
                                name="userName"
                                label="Nombre"
                                v-model="editedItem.first_name"
                                outlined
                                
                            ></v-text-field>
                            <v-text-field
                                name="userLastName"
                                label="Apellidos"
                                v-model="editedItem.last_name"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                name="userEmail"
                                label="Correo electrónico"
                                v-model="editedItem.email"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                name="userPass"
                                label="Contraseña"
                                type="password"
                                v-model="editedItem.password"
                                outlined
                                v-if="!editedItem._id"
                            ></v-text-field>
                            <v-select
                                name="userGender"
                                label="Genero"
                                :items="['Masculino','Femenino']"
                                v-model="editedItem.gender"
                                outlined
                            ></v-select>
                            <v-select
                                name="userRole"
                                label="Rol"
                                :items="['Administrador','Usuario']"
                                v-model="editedItem.role"
                                outlined
                            ></v-select>


                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit">
                            Guardar
                        </v-btn>
                        <v-btn color="error" outlined @click="closeUserDialog()">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
import Loading from '@/components/Loading'
import Users from '@/services/Users'
export default {
    components:{
        Loading
    },
    data:()=>({
        loading:false,
        userDialog:false,
        baseUrl:process.env.VUE_APP_SERVER_URL,
        headers:[
            { text: 'Avatar', value: 'avatar' },
            { text: 'Nombre', value: 'fullName' },            
            { text: 'E-Mail', value: 'email' },            
            { text: 'Genero', value: 'gender' },                    
            { text: 'Rol', value: 'role' },
            { text: '...', value: 'options' },
        ],
        editedItem:{},
        editetIndex:-1,
        items:[]
    }),
    methods:{
        async laodUsers(){
            this.loading=true
            try {
                let res=await Users.getUsers();
                this.items=res;
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        },
        async save(){
            this.loading=true;
            try {
                if(this.editetIndex==-1){
                    let res=await Users.store(this.editedItem);
                    this.items.push(res);
                }else{
                    let res=await Users.update(this.editedItem._id,this.editedItem);
                    this.items[this.editetIndex]=Object.assign(this.items[this.editetIndex],this.editedItem,{fullName:this.editedItem.first_name+" "+this.editedItem.last_name});
                }
                this.closeUserDialog();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        },
        updateItem(item){
            this.editedItem=Object.assign({},item);
            this.editetIndex=this.items.indexOf(item);
            this.userDialog=true;
        },
        closeUserDialog(){
            this.userDialog=false;
            this.$refs.userForm.resetValidation();
            this.editetIndex=-1;
            this.editedItem={};
        }
    },
    mounted(){
        this.laodUsers();
    },
    computed:{
        userTitle(){
            return this.editetIndex==-1?'Nuevo usuario':'Editar usuario';
        }
    }
}
</script>