<template>
    <div style="height:100%;overflow-y:auto">
        <v-container grid-list-xs >
            <v-card>
                <v-card-text>
                        <Loading :active="loading"></Loading>
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="text-h6">
                                    Imagen de perfil
                                </div>
                                <div class="text-caption">
                                    Aqui podras cambiar la imagen para tu perfil.
                                </div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-avatar
                                            size="110"
                                            color="grey"
                                        >
                                            <v-img v-if="user.avatar" :src="baseUrl+user.avatar"></v-img>
                                            <v-icon v-else x-large color="white">fa-user</v-icon>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <div>
                                            Seleccionar imagen de perfil
                                        </div>
                                        <div>
                                            <v-btn color="success" dark @click="$refs.avatarFile.click()">Cambiar archivo</v-btn>
                                            <input
                                                ref="avatarFile"
                                                v-show="false"
                                                accept="image/png, image/jpeg, image/bmp"
                                                type="file"
                                                @change="selectImage($event)"
                                            ><input>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="text-h6">
                                    Información principal
                                </div>
                                <div class="text-caption">
                                    Esta información es la que aparece tu perfil
                                </div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-form lazy-validation ref="profileForm" @submit.prevent="updateMe()">
                                    <v-row>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-text-field
                                                name="userName"
                                                label="Nombre"
                                                v-model="editedItem.first_name"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-text-field
                                                name="userLastName"
                                                label="Apellidos"
                                                v-model="editedItem.last_name"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-text-field
                                                name="userEmail"
                                                label="Email"
                                                v-model="editedItem.email"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>                        
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-select
                                                name="userGender"
                                                label="Genero"
                                                v-model="editedItem.gender"
                                                outlined
                                                dense
                                                :items="['Femenino','Masculino']"
                                            ></v-select>
                                        </v-col>                        
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4" class="text-center">
                                            <v-btn color="secondary" type="submit">Actualizar perfil</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <div class="text-h6">
                                    Contraseña
                                </div>
                                <div class="text-caption">
                                    Despues de cambiar la contraseña, se le redirigira a la pantalla de inicio de sesión donde podra iniciar sesión con su nueva contraseña.
                                </div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-form lazy-validation ref="passForm" @submit.prevent="updatePass()">
                                    <v-row>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-text-field
                                                name="userOldPass"
                                                label="Contraseña actual"
                                                v-model="auth.oldPass"
                                                outlined
                                                :error="!!auth.e"
                                                :error-messages="auth.e"
                                                dense
                                                type="password"
                                                :rules="[(v)=> !!v ||'Este campo es obligatorio']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-text-field
                                                name="userNewPass"
                                                label="Nueva contraseña"
                                                v-model="auth.newPass"
                                                outlined
                                                dense
                                                type="password"
                                                :rules="[(v)=> !!v ||'Este campo es obligatorio']"
                                            ></v-text-field>
                                        </v-col>                        
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" lg="4">
                                            <v-text-field
                                                name="userRePass"
                                                label="Repetir contraseña"
                                                v-model="auth.rePAss"
                                                outlined
                                                dense
                                                type="password"
                                                :items="['Femenino','Masculino']"
                                                :rules="passRule"
                                            ></v-text-field>
                                        </v-col>                        
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4" class="text-center">
                                            <v-btn color="secondary" type="submit" :loading="btnLoading" :fab="btnLoading">Actualizar Contraseña</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                
                            </v-col>
                            
                        </v-row>
                        <v-dialog
                            v-model="imageDialog"
                            scrollable
                            persistent :overlay="false"
                            max-width="500px"
                            transition="dialog-transition"
                        >
                            <v-form @submit.prevent="save()">
                                <v-card>
                                    <v-card-title primary-title class="primary white--text" style="height:80px" >
                                        Seleccionar avatar
                                        <v-spacer></v-spacer>
                                        <v-btn color="white" icon small @click="closeDialog()">
                                            <v-icon small>fa-times</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container grid-list-xs>
                                            <img ref="imagePreview" :src="profileAvatar" alt="" style="height:300px;width:100%">
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="success" type="submit">
                                            Aceptar
                                        </v-btn>
                                        <v-btn color="error" @click="closeDialog()" outlined >Cancelar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                            
                        </v-dialog>
                </v-card-text>
            </v-card>
         
        </v-container>
    </div>
   
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import Users from '@/services/Users.js'
import Loading from '@/components/Loading';
export default {
    components:{Loading},
    data:()=>({
        loading:false,
        btnLoading:false,
        imageDialog:false,
        baseUrl:process.env.VUE_APP_SERVER_URL,
        profileAvatar:"",
        imageFiles:null,
        auth:{

        },
        editedItem:{}
    }),
    watch:{
        user(v){
            this.editedItem=Object.assign({},v);
        }
    },
    methods:{
        ...mapMutations(['setUser']),
        selectImage(event){
            let self=this;
            let file=event.target.files[0];
            self.imageFiles=file;
            var reader = new FileReader();                
            reader.onload = function(e) {
                self.profileAvatar=e.target.result;
            }
            reader.readAsDataURL(file);
            this.imageDialog=true;
            
        },
        closeDialog(){
            this.imageFiles=null;
            this.imageDialog=false;
        },
        async save(){
            this.loading=true;
            try {
                let res=await Users.updateAvatar({image:this.imageFiles});
                this.setUser({...this.user,avatar:res.file});
                this.closeDialog();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
            
        },
        async updateMe(){
            this.loading=true;
            try {
                if(!this.$refs.profileForm.validate())throw {error:"profile validate"};
                await Users.updateMe(this.editedItem);
                this.setUser(this.editedItem);
            } catch (error) {
                console.error(error);
            }finally{
                this.loading=false;
            }
        },
        async updatePass(){
            this.btnLoading=true;
            try {
                if(!this.$refs.passForm.validate())throw {error:"pass validate"};                
                await Users.updatePass(this.auth);
                this.logOut();
            } catch (error) {
                console.log(error.response.data.error);
                if(error.response.data.error=='error pass')this.auth.e="Contraseña incorrecta"
                console.log(error);
            }finally{
                this.btnLoading=false;
            }
        },
        logOut(){
            this.$session.remove("session");
            localStorage.removeItem("session");
            window.location.href="/login";
        }
    },
    created(){
        this.editedItem=Object.assign({},this.user);
    },
    computed:{
        ...mapState(['user']),
        passRule(){
            return [
                (v)=> !!v || 'El campo es obligatorio',
                (v)=> !!v && this.auth.newPass==v || 'Las contraseñas no coinciden'
            ]
        }
    }
}
</script>