<template>
    <v-container fluid >
        <Loading :active="loading"></Loading>
        <v-card  min-height="80%">
            <v-card-title primary-title>
                Vehículos
                <v-spacer></v-spacer>
                <v-btn color="secondary" small @click="newDialog=true">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    
                >
                    <template v-slot:item.options="{ item }">
                        <v-btn color="error" small icon @click="updateItem(item)">
                            <v-icon x-small>fa-pen</v-icon>
                        </v-btn>
                        <v-btn color="secondary" small icon>
                            <v-icon x-small>fa-map-marked-alt</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="newDialog"
            scrollable
            persistent
            :overlay="false"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-form @submit.prevent="save()" lazy-validation ref="newForm">
                <v-card>
                    <v-card-title primary-title class="primary white--text" style="height:100px">
                        <span>{{title}}</span>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" icon @click="closeNewDialog()">
                            <v-icon small>fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs>
                            <v-text-field
                                name="idField"
                                label="Identificador"
                                id="id"
                                outlined
                                v-model="editedItem._id"
                                :disabled="editedIndex>=0"
                                color="secondary"
                            ></v-text-field>
                            <v-text-field
                                name="AliasField"
                                label="Alias"
                                id="AliasField"
                                outlined
                                v-model="editedItem.name"
                                color="secondary"
                            ></v-text-field>
                            <v-text-field
                                name="RegisterField"
                                label="Placas"
                                id="RegisterField"
                                outlined
                                v-model="editedItem.register"
                                color="secondary"
                            ></v-text-field>
                            <v-select
                                :items="actualDrivers"
                                item-text="name"                                
                                label="Conductores"
                                id="DriverField"
                                outlined
                                v-model="editedItem.driver"
                                return-object
                            ></v-select>>
                            
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit">Aceptar</v-btn>
                        <v-btn color="primary" outlined @click="closeNewDialog()">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>
import Device from '@/services/Device'
import Drivers from '@/services/Drivers'
import Loading from '@/components/Loading'
export default {
    components:{
        Loading
    },
    data:()=>({
        loading:false,
        newDialog:false,
        items:[],
        drivers:[],
        headers:[
            {
                text: '#',
                align: 'center',                
                value: '_id'
            },
        // { text: 'Medición', value: 'mesure' },
            { text: 'Alias', value: 'name' },
            { text: 'Placas', value: 'register' },
            { text: 'Estatus', value: 'status'},
            { text: 'Conductor', value: 'driver.name'},
            { text: '...', value: 'options', sortable:false},
        ],
        editedIndex:-1,
        editedItem:{
            driver:{}
        }
    }),
    watch:{
        newDialog(v){
            if(v)this.loadDrivers();
        }
    },
    methods:{
        async loadDevices(){
            try {
                let result=await Device.getDevices();
                this.items=result;
            } catch (error) {
                console.log(error);
            }
        },
        async loadDrivers(){
            try {
                let result=await Drivers.getWithoutDevice();
                this.drivers=result;
            } catch (error) {
                console.log(error);
            }
            
        },
        updateItem(item){
            this.editedIndex=this.items.indexOf(item);
            this.editedItem=Object.assign({},item);
            this.newDialog=true;
        },
        closeNewDialog(){
            this.editedIndex=-1;
            this.editedItem={driver:{}};
            this.$refs.newForm.resetValidation();
            this.newDialog=false;
        },
        async save(){
            this.loading=true;
            try {
                if(this.editedIndex>=0){
                    let res=await Device.update(this.editedItem._id,this.editedItem);
                    this.items[this.editedIndex]=Object.assign(this.items[this.editedIndex],this.editedItem);
                }else{
                    let res=await Device.store(this.editedItem);
                    this.items.push(this.editedItem);                    
                }
                this.closeNewDialog();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading=false;
            }
        }
    },
    created(){
        this.loadDevices();
        this.loadDrivers();
    },
    computed:{
        title(){
            return this.editedIndex==-1?'Nuevo vehículo':'Editar vehículo'
        },
        actualDrivers(){
            if(this.editedItem.driver._id)
                return [...this.drivers,this.editedItem.driver]
            else{
                return this.drivers;
            }
        }
    }
}
</script>