import axios from 'axios'
const URL = process.env.VUE_APP_SERVER_URL+"devices";
class Devices extends axios{
    
    constructor(){

    }
    static async getDevices(){
        let result=(await this.get(URL)).data;
        return result;
    }
    static async store(device){
        let result=(await this.post(URL,device)).data;
        return result;
    }
    static async update(id,device){
        let url=URL+"/"+id
        let result=(await this.put(url,device)).data;
        return result;
    }
    static async lockUnlock(device,status){
        let url=URL+"/lock/"+device
        let result=(await this.put(url,{status})).data;
        return result;
    }
}
export default Devices;