<template>
    <v-app class="overflow-hidden">
        <div style="height:100%;border:1px solid #000" >
            <v-row style="height:100%" class="ma-0">
                <v-col cols="12" md="4" lg="3">
                    <v-row align="center" justify="center" style="height:100%">
                        <v-col>
                            <v-form lazy-validation ref="form-auth" v-on:submit.prevent="auth()">
                                <v-container grid-list-xs>
                                    <v-card elevation="0">
                                        <v-card-text>
                                            <div class="center text-h6 ma-5" >
                                                Iniciar Sesión
                                            </div>
                                            <div>
                                                <v-text-field
                                                    name="userName"
                                                    label="Usuario/e-mail"
                                                    v-model="form.email"
                                                    
                                                    rounded
                                                    filled
                                                ></v-text-field>
                                                <v-text-field
                                                    name="userPass"
                                                    label="Contraseña"
                                                    v-model="form.password"
                                                    type="password"
                                                    rounded
                                                    filled
                                                ></v-text-field>
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn type="submit" color="primary" large block rounded>Iniciar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-container>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="0" md="8" lg="9" class="secondary pa-0" >
                    
                </v-col>
            </v-row>
        </div>
    </v-app>
    
</template>
<script>
import Users from '@/services/Users';
export default {
    data:()=>({
        form:{

        }
    }),
    methods:{
        async auth(){
            console.log("a ver que onda");
            try {
                let res=await Users.auth(this.form);
                this.$session.set("session",res.token);
                this.$router.push("/");
                
            } catch (error) {
                console.log(error);
            }
        }
    },
    created(){

    }
}
</script>