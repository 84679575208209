import axios from 'axios'
const URL = process.env.VUE_APP_SERVER_URL+"gpsdata";
class Data extends axios{
    
    constructor(){

    }
    static async getData(headers){
        let url=URL;
        let result=(await this.get(url,{headers:this.getHeaders(headers)})).data;
        return result;
    }
    static async getLast(){
        let url=URL+'/last'
        let result=(await this.get(url)).data;
        return result;
    }
    
    static getHeaders(headers){
        let result={};
        try {            
            for (const opt in headers) {
                result[opt]=JSON.stringify(headers[opt]);                
            }
        } catch (error) {
            console.log(error);
        }
        
        return result;
    }
}
export default Data;